import * as React from "react"

import {Box, Button, Flex, Link, Text} from "@chakra-ui/react";
import {getData} from "../api/getData";
import {LOG} from "../tools";
import {useEffect, useState} from "react";
import {ExternalLinkIcon, SearchIcon} from "@chakra-ui/icons";
import {VERSION_METHODO} from "./tools";

const MethodoStreamData = ({})=>{

    const [listWord,setListWord] = useState([])
    const [listFollow,setListFollow] = useState([])
    const [needLoad,setNeedLoad] = useState(false)
    useEffect(() =>{
        if (needLoad){
            getData("methodo/stream.json",VERSION_METHODO).then((d) =>{
            LOG("stream",d)
                setListWord(d["tags"])
                setListFollow(d["follow"])
        });
        }

    },[needLoad])

    return <Box minHeight={"100px"}>
        {!needLoad && <Flex height={"100px"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}><Button colorScheme={"gray"} variant='outline' rightIcon={<SearchIcon/>} onClick={() => {
            setNeedLoad(true)
        }}  color={"text.200"} fontWeight={"body"}> Afficher le contenu des deux critères</Button> </Flex>}
        {needLoad && <Box>
            <Text textDecoration={"underline"} mt={"3px"}>Liste des hashtags suivis : </Text>
            <Flex flexWrap={"wrap"} fontSize={"16px"} p={2}>{listWord.map((t)=><Box  p={"2px"} m={"2px"}
            borderRadius={"3px"} borderColor={"bg.900"} borderWidth={"1px"} borderStyle={"solid"}
            >{t}</Box>)}</Flex>

            <Text textDecoration={"underline"} mt={"3px"}>Liste des comptes suivis : </Text>
            <Flex flexWrap={"wrap"} fontSize={"16px"} p={2}>{listFollow.map((user_id)=><Box  p={"2px"} m={"2px"}
            borderRadius={"3px"} borderColor={"bg.900"} borderWidth={"1px"} borderStyle={"solid"}
            ><Link href={`https://twitter.com/i/user/${user_id}`}  isExternal color={"blue.300"}>
                {user_id} <ExternalLinkIcon mx='2px' />
                </Link>  </Box>)}</Flex>

        </Box>

        }
    </Box>
}

export default MethodoStreamData