import * as React from "react"

import {Avatar, Box, Button, Container, Flex, Heading, Icon, Link, Text} from "@chakra-ui/react";

import {motion} from "framer-motion";
import {HeaderSectionBig, HeaderSectionBigMain} from "../mainPage/HeaderSection";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import MethodoStreamData from "./MethodoStreamData";
import MethodoPersonPartiData from "./MethodoPersonPartiData";
import MethodoVocabData from "./MethodoVocabData";
import {TwitterDMButton, TwitterFollowButton} from "react-twitter-embed";
import {FiLinkedin} from "react-icons/fi";
import MathJax from 'react-mathjax';


const ContentMethodology = ({})=>{


    return <Box p={[0,5,10]}>
        <HeaderSectionBigMain  text={"Méthodologie"} idName={"methodologie"}/>
        <Box fontSize={["18px","21px"]} p={1}>
        <Box>
            <Text >Cette page a pour objectif principale de détailler la méthodologie relative à la <strong>récupération des données</strong> et aux  <strong>traitements des données</strong> réalisés.

  <Text> N’hésitez pas à nous suivre et nous contacter si la méthodologie vous paraît erronnée ou incomplète 🤗 </Text>
              <Flex flexWrap={"wrap"} alignItems={"center"} p={1}>
                  <Link href={"https://www.linkedin.com/in/cavallonicolas/"} target="_blank"><Button size={"sm"} m={2} colorScheme='twitter' leftIcon={<FiLinkedin />}>
                            Linkedin
                  </Button></Link>
                  <TwitterFollowButton  lang={"fr"} options={{size:"large"}} screenName={'voixElections'} /></Flex>


</Text>
</Box>
            <HeaderSectionBig text={"1. Collecte des données"} idName={``}/>
             <Box fontSize={["18px","21px"]} p={[1,2]}>
            <Text>Nous utilisons la fonctionnalité de stream de Twitter :  <Link href='https://developer.twitter.com/en/docs/tutorials/stream-tweets-in-real-time' isExternal color={"text.200"}>
                  Stream Tweets in real-time <ExternalLinkIcon mx='2px' />
                </Link>
            </Text>
            <Text>
                En utilisant l’API Twitter vous pouvez simplement être en écoute sur des tweets qui contiennent certains hashtags (1er critère) ou qui ont été postés par des comptes sélectionnés (2ème critère).
                On <strong>collecte ainsi tous les tweets respectant ces deux critères</strong>. Nous collectons également <strong>tous les retweets ou commentaires de ces premiers tweets.</strong>   </Text>
            <MethodoStreamData/>
             </Box>

            <HeaderSectionBig text={"2. Traitement des données"} idName={``}/>
            <HeadingMethodoSub  title={"a. Nettoyage du texte"}/>
                <Box p={[1,2,3]} pt={[0,0,0]}>
                    <Text>Les tweets contiennent une information textuelle. Ce contenu est analysé en utilisant la librairie
                        python <Link href={"https://spacy.io/"} color={"text.200"}>Spacy</Link>.</Text>
                    <Text>Nous considérons qu’un retweet propage le contenu textuel du tweet retweeté.
                        Par conséquence si le contenu texte d’un tweet parle de la thématique “climat” nous considérons qu’il en est de même pour tous ses retweets. </Text>
                </Box>
            <HeadingMethodoSub  title={"b. Classification des tweets"}/>
             <Box p={[1,2,3]} pt={[0,0,0]}>
            <Text>Pour des raisons de transparence, pour le moment seuls des modèles simples sont utilisés pour associer un tweet à une thématique, personnalité politique ou à un parti politique.
                La présence d'un mot, hashtag ou l'auteur du tweet sont les moyens appliqués aujourd'hui pour catégoriser les tweets</Text>
             <HeadingMethodoSub2  title={"Comment un tweet est associé à une personnalité ou un parti : "}/>

            <MethodoPersonPartiData />
            <HeadingMethodoSub2  title={"Comment le contenu d’un tweet est associé à une thématique :\n"}/>
            <MethodoVocabData />
             </Box>

            <HeaderSectionBig text={"3. Extrapolation des résultats"} idName={``}/>
            <Box p={[1,2,3]} pt={[0,0,0]}>
                <Text>Par défaut, les résultats sont présentés
                    en considérant l’ensemble des tweets et retweets analysés au même
                    niveau : <strong>chaque tweet et retweet ont un poids égale</strong>.</Text>

                <Text>Extrapoler les informations issues d’un contenu observé (pour le moment principalement de Twitter) est un travail complexe.
                    Aujourd’hui on peut difficilement conclure sur l’activité politique française à partir du simple contenu Twitter. Un petit article évoquant ce sujet sortira bientôt
                    sur le <Link href={"https://blog.octo.com/"} color={"text.200"}>blog d’Octo Technology</Link> 🤓

                <HeadingMethodoSub2  title={'Variation de représentation "Chaque utilisateur a le même poids"'}/>
                    <Text>Pour pallier le poids de certaines communautés retweetant quasi automatiquement
                        et gonflant de façon artificielle l’activité réelle, une représentation alternative sera proposée
                        où le poids de chaque utilisateur sera équivalente (vs chaque tweet a le même poids).
L'analyse de messages politiques se doit d’être le plus impartiale possible. C’est pourquoi si cette représentation est utilisée, sa définition doit être bien définie. J’espère terminer mes études sur ce sujet 🤔 et communiquer rapidement sur cette possible nouvelle représentation.
</Text>
</Text>
            </Box>

            <HeaderSectionBig text={"4. Les autres concepts"} idName={``}/>
            <Box p={[1,2,3]} pt={[0,0,0]}>
                 <HeadingMethodoSub2  title={"Lorsqu'on parle de significativité du vocabulaire"}/>
                <MathJax.Provider>
                <Text>Afin d’évaluer si un vocabulaire est représentatif d’un groupe,
                    ou d’une période de temps nous calculons la fréquence de ce vocabulaire (<MathJax.Node inline formula={`n_{observed}`} />)
                    dans ce groupe et nous la comparons à la fréquence (<MathJax.Node inline formula={`n_{expected}`} />)
                    qu’il a dans l’ensemble de notre périmètre (ensemble des tweets).
                    <br/>Nous calculons la valeur suivante que certains en France appelent le Chi-deux signé : </Text>
                <MathJax.Node formula={ `Importance = \\frac{( n_{observed} - n_{expected} )}{\\sqrt{n_{expected}}}`} />
        </MathJax.Provider>

            </Box>


        </Box>
    </Box>
}

const HeadingMethodoSub = ({title})=>{
    return <Heading mt={[2,"18px","25px"]} pl={[1,2]} textTransform={"none"}  as={"h4"} fontSize={"18px"}>{title}</Heading>
}
const HeadingMethodoSub2 = ({title})=>{
    return <Heading mt={[2,"18px","25px"]} pl={[0]} textTransform={"none"} fontFamily={"body"} as={"h5"} fontSize={"18px"}>{title}</Heading>
}

export default ContentMethodology