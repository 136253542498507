import * as React from "react"

import Layout from "../components/layout"
import {Avatar, Box, Container, Flex, Heading, Icon, Link, Text} from "@chakra-ui/react";

import {DEBUG} from "../components/tools";
import ContentMethodology from "../components/methodology/ContentMethodology";
import {ContentIndex} from "../components/mainPage/ContentIndex";

const Lab = ({ }) => {
  return (
    <Layout title={"La méthodologie"}>
        <Container maxW="container.xl" sx={{
                margin: `10px auto`,

                pl:['2px','10px','10px'],
                pr:['2px','10px','10px'],
            }}>
<ContentMethodology />
        </Container>
    </Layout>
  )
}
export default Lab