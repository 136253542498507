import * as React from "react"

import {Avatar, Box, Button, Flex, Link, Text} from "@chakra-ui/react";
import {getData} from "../api/getData";
import {LOG} from "../tools";
import {useEffect, useState} from "react";
import {ExternalLinkIcon, SearchIcon} from "@chakra-ui/icons";
import {getUrlImgFromTagId, LIST_ALL_PARTI, LIST_ALL_PERSON, LIST_ALL_VOCAB} from "../tag/tools";
import {VERSION_METHODO} from "./tools";

const MethodoVocabData = ({})=>{

    const [jsonVocab,setJsonVocab] = useState({})
    const [needLoad,setNeedLoad] = useState(false)
    useEffect(() =>{
        if (needLoad){
            getData("methodo/v.json",VERSION_METHODO).then((d) =>{
            LOG("v",d)
            setJsonVocab(d)
        });
        }
    },[needLoad])

    return <Box minHeight={"100px"}>
        {!needLoad && <Flex height={"100px"} justifyContent={"center"}
                            pl={[0,2,4]} flexDirection={"column"} alignItems={"flex-start"}><Button colorScheme={"gray"} variant='outline' rightIcon={<SearchIcon/>} onClick={() => {
            setNeedLoad(true)
        }}  color={"text.200"} fontWeight={"body"}> Afficher les informations de chaque thématique </Button> </Flex>}
        {needLoad && <Box>
            <Text mt={"10px"}  ml={"5px"}  textDecoration={"underline"}>Les thématiques :</Text>
            <Flex flexWrap={"wrap"}>
                {LIST_ALL_VOCAB.map((t)=><TagCard key={t.id} tag_info={t} tag_data ={jsonVocab.hasOwnProperty(t.id)?jsonVocab[t.id]:{}} />)}
            </Flex>
        </Box>
        }
    </Box>
}

const TagCard = ({tag_info,tag_data})=>{
    const name = tag_info.n
    const listTokens = tag_data.hasOwnProperty("lt")?tag_data["lt"]:[]
return <Flex flexDirection={"column"} justifyContent={"space-between"} alignItems={"left"} m={[1,"6px"]} p={1}
             borderRadius={"5px"}  borderWidth={"1px"} borderStyle={"solid"} borderColor={"bg.900"} >
    <Text fontSize={"17px"} textTransform={"uppercase"} fontWeight={"bold"} lineHeight={1}>{name}</Text>
    <Box width={["100%","100%"]} p={1}>
        <Text fontSize={"13px"} color={"text.200"}>Le tweet contient :</Text>
        <Flex flexWrap={"wrap"}>{listTokens.map((t) => <Box  p={"2px"} m={"2px"} fontSize={"14px"}
            borderRadius={"3px"} borderColor={"bg.900"} borderWidth={"1px"} borderStyle={"solid"} bg={"white"}
            >{t}</Box>)}</Flex>
    </Box>
     </Flex>
}

export default MethodoVocabData