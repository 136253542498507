import * as React from "react"

import {Avatar, Box, Button, Flex, Link, Text} from "@chakra-ui/react";
import {getData} from "../api/getData";
import {LOG} from "../tools";
import {useEffect, useState} from "react";
import {ExternalLinkIcon, SearchIcon} from "@chakra-ui/icons";
import {getUrlImgFromTagId, LIST_ALL_PARTI, LIST_ALL_PERSON} from "../tag/tools";
import {VERSION_METHODO} from "./tools";

const MethodoPersonPartiData = ({})=>{

    const [jsonPersonParti,setJsonPersonParti] = useState({})
    const [needLoad,setNeedLoad] = useState(false)
    useEffect(() =>{
        if (needLoad){
            getData("methodo/p_pp.json",VERSION_METHODO).then((d) =>{
            LOG("p_pp",d)
            setJsonPersonParti(d)
        });
        }

    },[needLoad])

    return <Box minHeight={"100px"}>
        {!needLoad && <Flex height={"100px"} justifyContent={"center"}
                            pl={[0,2,4]} flexDirection={"column"} alignItems={"flex-start"} >
            <Button  colorScheme={"gray"} variant='outline' rightIcon={<SearchIcon/>} onClick={() => {
            setNeedLoad(true)
        }}  color={"text.200"} fontWeight={"body"}> Afficher les informations <br/>pour chaque personnalité et parti </Button> </Flex>}
        {needLoad && <Box>
            <Text mt={"10px"}  ml={"5px"}  textDecoration={"underline"}>Personnalités :</Text>
            <Flex flexWrap={"wrap"}>
                {LIST_ALL_PERSON.map((t)=><TagCard key={t.id} tag_info={t} tag_data ={jsonPersonParti.hasOwnProperty(t.id)?jsonPersonParti[t.id]:{}} />)}
            </Flex>
            <Text  mt={"10px"} ml={"5px"} textDecoration={"underline"}>Partis politiques :</Text>
            <Flex flexWrap={"wrap"}>
                {LIST_ALL_PARTI.map((t)=><TagCard key={t.id} tag_info={t} tag_data ={jsonPersonParti.hasOwnProperty(t.id)?jsonPersonParti[t.id]:{}} />)}
               </Flex>
        </Box>

        }
    </Box>
}

const TagCard = ({tag_info,tag_data})=>{
    LOG("tag_info",tag_info)
    const tagId = tag_info.id
    const name = tag_info.n
    const hashtags = tag_data.hasOwnProperty("ht")?tag_data["ht"]:[]
    const user_id = tag_data.hasOwnProperty("uid")?tag_data["uid"]:null
    const members = tag_data.hasOwnProperty("members")?tag_data["members"]:null
 const urlImg =  getUrlImgFromTagId(tagId,50)
return <Flex minHeight={"150px"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"center"} m={[1,"6px"]} p={1} borderRadius={"5px"}  boxShadow={"card"} borderWidth={"1px"} borderStyle={"solid"} borderColor={"text.50"} bg={"white"}>
    <Avatar src={urlImg} width={50} />
    <Text fontSize={"15px"} fontWeight={"bold"} lineHeight={1}>{name}</Text>
    <Box width={["100%","250px"]} p={1}>
        <Text fontSize={"13px"} color={"text.200"}>Le tweet contient :</Text>
        <Flex flexWrap={"wrap"}>{hashtags.map((t) => <Box  p={"2px"} m={"2px"} fontSize={"13px"}
            borderRadius={"3px"} borderColor={"bg.900"} borderWidth={"1px"} borderStyle={"solid"}
            >{t}</Box>)}</Flex>
        <Text fontSize={"13px"} color={"text.200"}>ou provient du compte :
            {user_id != null  && <Link ml={1} href={`https://twitter.com/i/user/${user_id}`}  isExternal color={"blue.300"}>
                {user_id}</Link>}

        </Text>
        {members!= null && <Box>
            <Text fontSize={"13px"} color={"text.200"}>ou concerne :</Text>
            <Flex flexWrap={"wrap"}>{members.map((t) => <Avatar m={1} src={getUrlImgFromTagId(t,50)} w={"25px"} h={"25px"}/>)}</Flex>
        </Box>}
    </Box>
     </Flex>
}

export default MethodoPersonPartiData